import { JSEncrypt } from "jsencrypt";

const encryptPublicKey = process.env.REACT_APP_ENCRYPT_DATA_KEY;

export default function EncryptField(field) {
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(encryptPublicKey);
   
    return jsEncrypt.encrypt(field);
  }
  