import React, { useState, useContext } from "react";
import { api } from "../services/AxiosService";
import Tracking from "../hooks/Tracking";
import licenseAndPlateCriptografy from "../helpers/licenseAndPlateCriptografy";
import EncryptField from "../functions/Encrypt";

export const AuthContext = React.createContext({});

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [errorRedirect, setErrorRedirect] = useState();
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [SMSPage, setSMSPage] = useState();
  const [captchaResponse, setCaptchaResponse] = useState("");
  const [clickCaptcha, setClickCaptcha] = useState(false);
  const [message409, setMessage409] = useState();
  const [statusEmail, setStatusEmail] = useState();
  const [statusRegistration, setStatusRegistration] = useState();
  const [number, setNumber] = useState("");
  const [registrationPage, setRegistrationPage] = useState();
  const [placaPage, setPlacaPage] = useState();
  const [home, setHome] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notification200, setNotification200] = useState(false);
  const [notification400, setNotification400] = useState(false);
  const [notification404, setNotification404] = useState(false);
  const [notification409, setNotification409] = useState(false);
  const [phoneReturnEmail, setphoneReturnEmail] = useState("");
  const [state409, setState409] = useState(false);
  const reCaptchaRef = React.createRef();

  const isHttpSuccess = (status) => {
    return 2 == Math.floor(status / 100);
  };

  const cryptographyHeader = {'x-content-body': 'crypt'};

  const verifyEmail = (email) => {

    Tracking.sendEvent({
      category: "login_email",
      action: "LOGIN_CONSULTA_EMAIL_CLICK",
      label: "Verificando e-mail",
    });

    clickCaptcha === true ? setLoading(true) : "";

    setNotification400(false);
    
    api
      .get(`/auth/condutores/${email}/ativo`)
      .then((result) => {
        setLoading(false);
        if (isHttpSuccess(result.status) === true) {
          Tracking.sendEvent({
            category: "login_email",
            action: "LOGIN_CONSULTA_EMAIL_SUCESSO",
            label: "E-mail está na base de dados",
          });
          setStatusEmail(true);
          setSMSPage(false);
          localStorage.setItem("user-email", email);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (
          error.response.status === 400 &&
          error.response.data.Message === "E-mail de origem LQV"
        ) {
          Tracking.sendEvent({
            category: "login_email",
            action: "LOGIN_EMAIL_FROTA360",
            label: "Email do FROTA360",
          });
          setNotification400(true);
        } else if (
          error.response.status === 400 &&
          error.response.data === "Captcha inválido"
        ) {
          Tracking.sendEvent({
            category: "login_email",
            action: "LOGIN_EMAIL_CAPTCHA_INVÁLIDO_ERRO",
            label: "Captcha inválido.",
          });
          setError("Captcha inválido. Atualize a página e tente novamente.");
        } else if (
          error.response.status === 404 ||
          error.response.status === 409
        ) {
          error.response.status === 404
            ? Tracking.sendEvent({
                category: "login_email",
                action: "LOGIN_CONSULTA_EMAIL_NAO_ENCONTRADO_ERRO",
                label: "Email não está na base de dados.",
              })
            : "";
          if (error.response.status === 409) {
            Tracking.sendEvent({
              category: "login_email",
              action: "LOGIN_CONSULTA_EMAIL_CONFLITO_ERRO",
              label: email,
            });
            setState409(true);
          }
          setSMSPage(true);
        } else {
          Tracking.sendEvent({
            category: "login_email",
            action: "LOGIN_CONSULTA_EMAIL_SERVIDOR_ERRO",
            label: "Error ao verificar e-mail.",
          });
          setError("Serviço indisponível. Tente novamente mais tarde.");
        }
      });
  };

  const refreshFirebaseToken = async (token) => {
    const requestData = {
      FirebaseToken: token,
    };

    const bodyToString = JSON.stringify(requestData);

    return await api
      .post("auth/provider/RefreshFirebaseToken", EncryptField(bodyToString), {
        headers: cryptographyHeader,
      })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error;
      });
  };

  const createUserWithEmailAndPasswordFb = async (email, password) => {
    const requestData = {
      Username: email,
      Password: password,
    };

    const bodyToString = JSON.stringify(requestData);

    return await api
      .post("auth/provider/CreateNewUser", EncryptField(bodyToString), {
        headers: cryptographyHeader,
      })
      .then((result) => {
        return result;
      })
      .catch((error) => {});
  };

  const sendEmailVerificationFb = async (token) => {
    const requestData = {
      FirebaseToken: token,
    };

    const bodyToString = JSON.stringify(requestData);

    return await api
      .post("auth/provider/SendEmailVerification",  EncryptField(bodyToString), {
        headers: cryptographyHeader,
      })
      .then((result) => {
        return result;
      })
      .catch((error) => {});
  };

  const sendPasswordResetEmailFb = async (email) => {
    const requestData = {
      Username: email,
    };

    const bodyToString = JSON.stringify(requestData);

    return await api
      .post("auth/provider/SendResetPassword", EncryptField(bodyToString), {
        headers: cryptographyHeader,
      })
      .then((result) => {
        return result;
      })
      .catch((error) => {});
  };

  const fetchSignInMethodsForEmailFb = async (email) => {
    const requestData = {
      Username: email,
    };

    const bodyToString = JSON.stringify(requestData);

    return await api
      .post("auth/provider/CheckEmail", EncryptField(bodyToString), {
        headers: cryptographyHeader,
      })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error;
      });
  };

  const signInWithEmailAndPasswordFb = async (authMethod, email, password) => {
    const requestData = {
      AuthMethod: authMethod,
      Username: email,
      Password: password,
    };

    const bodyToString = JSON.stringify(requestData);

    setLoading(true);

    return await api
      .post("auth/provider/SingIn", EncryptField(bodyToString), {
        headers: cryptographyHeader,
      })
      .then((result) => {
        setLoading(false);
        clearErrors();
        return result;
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 400) {
          setError("Credenciais inválidas");
            return error;
        }
      });
  };

  const loginEmail = (email, uid, captcha) => {
    Tracking.sendEvent({
      category: "login_email",
      action: "LOGIN_COM_EMAIL_CLICK",
      label: "Login com e-mail e senha",
    });
    clickCaptcha === true ? setLoading(true) : "";
    let auth = process.env.REACT_APP_AUTHORIZATION;

    let headersCustom = {
      "recaptcha-token": captcha,
      [auth]: `Bearer ${uid}`,
      ...cryptographyHeader
    };

    const requestData = {
      email: email,
    };

    const bodyToString = JSON.stringify(requestData);

    api
      .post("auth/autenticacao", EncryptField(bodyToString), {
        headers: headersCustom,
      })
      .then((result) => {
        setLoading(false);
        localStorage.setItem("user-name", result.data.Nome);
        localStorage.setItem(
          "user-data",
          JSON.stringify(result.data.VeiculosVinculados)
        );
        if (result.data.VeiculosVinculados.length > 0) {
          const data = JSON.parse(
            JSON.stringify(result.data.VeiculosVinculados)
          );
          for (let i = 0; i < data.length; i++) {
            data[i].Renavam = licenseAndPlateCriptografy(data[i].Renavam);
          }
          localStorage.setItem("user-data", JSON.stringify(data));
          localStorage.setItem(
            "renavam",
            licenseAndPlateCriptografy(
              result.data.VeiculosVinculados[0].Renavam
            )
          );
          setHome(true);
          localStorage.setItem(
            "razao-social",
            result.data.VeiculosVinculados[0].RazaoSocial
          );
        } else {
          setPlacaPage(true);
        }
        Tracking.sendEvent({
          category: "login_email",
          action: "LOGIN_COM_EMAIL_SUCESSO",
          label: "Login com e-mail e senha realizado com sucesso.",
        });
      })
      .catch((error) => {
        Tracking.sendEvent({
          category: "login_email",
          action: "LOGIN_COM_EMAIL_SERVIDOR_ERRO",
          label: "Erro ao logar com e-mail e senha",
        });
        setLoading(false);
        setError("Serviço indisponível. Tente novamente mais tarde.");
      });
  };

  const verifyPhone = (celular, captcha) => {
    Tracking.sendEvent({
      category: "login_celular",
      action: "LOGIN_VERIFICANDO_CELULAR",
      label: "Verificando celular na base",
    });
    if (clickCaptcha === true) {
      setLoading(true);
      api
        .get(`/auth/condutores/${celular}/email`, {
          headers: { "recaptcha-token": captcha },
        })
        .then((result) => {
          setLoading(false);
          setphoneReturnEmail(result.data.Email);
          isHttpSuccess(result.status) === true ? setNotification200(true) : "";
          Tracking.sendEvent({
            category: "login_celular",
            action: "LOGIN_VERIFICANDO_CELULAR_SUCESSO",
            label: "Celular possui um e-mail vinculado.",
          });
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 404) {
            Tracking.sendEvent({
              category: "login_celular",
              action: "LOGIN_VERIFICANDO_CELULAR_NAO_ENCONTRADO",
              label: "Celular sem e-mail vinculado.",
            });
            setNotification404(true);
          } else if (error.response.status === 409) {
            Tracking.sendEvent({
              category: "login_celular",
              action: "LOGIN_VERIFICANDO_CELULAR_CONFLITO_ERRO",
              label: "Celular com mais de um e-mail vinculado",
            });
            setNotification409(true);
          } else {
            Tracking.sendEvent({
              category: "login_celular",
              action: "LOGIN_VERIFICANDO_CELULAR_SERVIDOR_ERRO",
              label: "Erro ao verificar e-mail através do celular.",
            });
            setError("Serviço indisponível. Tente novamente mais tarde.");
          }
        });
    }
  };

  const placaRegistration = (placa, renavam, uid) => {
    Tracking.sendEvent({
      category: "login_placa_email",
      action: "LOGIN_PLACA_EMAIL_VERIFICANDO",
      label: "Verificando placa ativa fluxo  e-mail",
    });
    if (clickCaptcha === true) {
      setLoading(true);
      let headersCustom = {};
      let auth = process.env.REACT_APP_AUTHORIZATION;
      headersCustom[auth] = `Bearer ${uid}`;
      api
        .get(`/veiculos/${placa}/ativo/${renavam}`, {
          headers: headersCustom,
        })
        .then((result) => {
          setLoading(false);
          const data = JSON.parse(JSON.stringify(result.data));
          data.Renavam = licenseAndPlateCriptografy(data.Renavam);
          localStorage.setItem("user-data", JSON.stringify(data));
          localStorage.getItem("user-name")
            ? ""
            : localStorage.setItem("user-name", "boas-vindas");
          localStorage.setItem("modelo-atual", result.data.Modelo);
          localStorage.setItem("razao-social", result.data.RazaoSocial);
          localStorage.setItem(
            "codigo-identificador-atual",
            result.data.CodigoIdentificadorCliente
          );
          localStorage.setItem("cnpj-atual", result.data.CnpjCliente);
          localStorage.setItem(
            "renavam",
            licenseAndPlateCriptografy(result.data.Renavam.toString())
          );
          isHttpSuccess(result.status) === true ? setHome(true) : "";
          Tracking.sendEvent({
            category: "login_placa_email",
            action: "LOGIN_PLACA_EMAIL_SUCESSO",
            label: "Login placa fluxo email realizado",
          });
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 404) {
            Tracking.sendEvent({
              category: "login_placa_email",
              action: "LOGIN_PLACA_EMAIL_NAO_ENCONTRADO_ERRO",
              label: "Veículo não encontrado",
            });
            setError(
              "Veículo não encontrado. Verifique se digitou os dados corretamente."
            );
          }
          if (error.response.status === 400) {
            Tracking.sendEvent({
              category: "login_placa_email",
              action: "LOGIN_PLACA_EMAIl_CAPTCHA_INVALIDO_ERRO",
              label: "Erro ao logar por placa e renavam.",
            });
            setError(
              "Serviço indisponível. Por favor, atualize a página e tente novamente."
            );
          } else if (error.response.status >= 500) {
            Tracking.sendEvent({
              category: "login_placa_email",
              action: "LOGIN_PLACA_EMAIL_SERVIDOR_ERRO",
              label: "Erro ao logar por placa e renavam.",
            });
            setError("Serviço indisponível. Tente novamente mais tarde.");
          } else {
            setError("Serviço indisponível. Tente novamente mais tarde.");
          }
        });
    }
  };

  const placaRegistrationMain = (placa, renavam, uid, captcha) => {
    if (clickCaptcha === true) {
      Tracking.sendEvent({
        category: "login_placa",
        action: "LOGIN_PLACA_VERIFICANDO",
        label: "Verificando placa ativa",
      });
      setLoading(true);
      setNotification400(false);

      let auth = process.env.REACT_APP_AUTHORIZATION;

      let headersCustom = {
        "recaptcha-token": captcha,
        [auth]: `Bearer ${uid}`,
        ...cryptographyHeader
      };

      const requestData = {
        placa: placa,
        renavam: renavam,
      };

      const bodyToString = JSON.stringify(requestData);

      api
        .post("auth/autenticacao", EncryptField(bodyToString), {
          headers: headersCustom,
        })
        .then((result) => {
          setLoading(false);
          const data = JSON.parse(JSON.stringify(result.data));

          // Modificação para o novo retorno
          data.Renavam = licenseAndPlateCriptografy(
            data.VeiculosVinculados[0].Renavam
          );

          localStorage.setItem("user-data", JSON.stringify(data));
          localStorage.getItem("user-name")
            ? ""
            : localStorage.setItem("user-name", "boas-vindas");
          localStorage.setItem(
            "placa-atual",
            result.data.VeiculosVinculados[0].Placa
          );
          localStorage.setItem(
            "modelo-atual",
            result.data.VeiculosVinculados[0].Modelo
          );
          localStorage.setItem(
            "razao-social",
            result.data.VeiculosVinculados[0].RazaoSocial
          );
          localStorage.setItem(
            "codigo-identificador-atual",
            result.data.VeiculosVinculados[0].CodigoIdentificadorCliente
          );
          localStorage.setItem(
            "cnpj-atual",
            result.data.VeiculosVinculados[0].CnpjCliente
          );
          localStorage.setItem(
            "renavam",
            licenseAndPlateCriptografy(
              result.data.VeiculosVinculados[0].Renavam.toString()
            )
          );

          if (isHttpSuccess(result.status) === true) {
            setHome(true);
          }

          Tracking.sendEvent({
            category: "login_placa",
            action: "LOGIN_PLACA_SUCESSO",
            label: "Login por placa efetuado.",
          });
        })
        .catch((error) => {
          setLoading(false);
          if (
            error.response.status === 400 &&
            error.response.data.Message === "Veículo de origem LQV"
          ) {
            Tracking.sendEvent({
              category: "login_placa",
              action: "LOGIN_PLACA_FROTA360",
              label: "Veículo do FROTA360",
            });
            setNotification400(true);
          } else if (
            error.response.status === 400 &&
            error.response.data === "Captcha inválido"
          ) {
            Tracking.sendEvent({
              category: "login_placa",
              action: "LOGIN_PLACA_CAPTCHA_INVÁLIDO_ERRO",
              label: "Veículo não encontrado.",
            });
            setError("Captcha inválido. Atualize a página e tente novamente.");
          } else if (error.response.status === 404) {
            Tracking.sendEvent({
              category: "login_placa",
              action: "LOGIN_PLACA_NAO_ENCONTRADA_ERRO",
              label: "Veículo não encontrado.",
            });
            setError(
              "Veículo não encontrado. Verifique se digitou os dados corretamente."
            );
          } else if (error.response.status >= 500) {
            Tracking.sendEvent({
              category: "login_placa",
              action: "LOGIN_PLACA_SERVIDOR_ERRO",
              label: "Erro ao logar por placa e renavam.",
            });
            setError("Serviço indisponível. Tente novamente mais tarde.");
          } else {
            setError("Serviço indisponível. Tente novamente mais tarde.");
          }
        });
    }
  };

  const placaRegistrationRedirectApp = (placa, renavam, uid) => {
    Tracking.sendEvent({
      category: "login_redirecionamento_app",
      action: "LOGIN_REDIRECIONAMENTO_APP",
      label: "Login com e-mail e senha",
    });
    setLoading(true);
    localStorage.setItem("renavam", renavam);
    let headersCustom = {
      "x-license-client": licenseAndPlateCriptografy(
        localStorage.getItem("renavam")
      ),
      "x-plate-client": licenseAndPlateCriptografy(
        localStorage.getItem("placa-atual")
      ),
    };
    let auth = process.env.REACT_APP_AUTHORIZATION;
    headersCustom[auth] = `Bearer ${uid}`;
    api
      .get(`/veiculos/${placa}/ativo/${renavam}`, {
        headers: headersCustom,
      })
      .then((result) => {
        setLoading(false);
        localStorage.setItem("user-data", JSON.stringify(result.data));
        localStorage.getItem("user-name")
          ? ""
          : localStorage.setItem("user-name", "boas-vindas");
        localStorage.setItem("modelo-atual", result.data.Modelo);
        localStorage.setItem("razao-social", result.data.RazaoSocial);
        localStorage.setItem(
          "codigo-identificador-atual",
          result.data.CodigoIdentificadorCliente
        );
        localStorage.setItem("cnpj-atual", result.data.CnpjCliente);
        localStorage.setItem(
          "renavam",
          licenseAndPlateCriptografy(localStorage.getItem("renavam"))
        );
        isHttpSuccess(result.status) === true ? setHome(true) : "";
        Tracking.sendEvent({
          category: "login_redirecionamento_app",
          action: "LOGIN_REDIRECIONAMENTO_APP_SUCESSO",
          label: "Login com e-mail e senha realizado com sucesso.",
        });
        setErrorRedirect(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrorRedirect(true);
        Tracking.sendEvent({
          category: "login_redirecionamento_app",
          action: "LOGIN_REDIRECIONAMENTO_APP_ERROR",
          label: "Erro ao logar com e-mail e senha",
        });
      });
  };

  //RECAPTCHA

  const redoCaptcha = () => {
    reCaptchaRef.current.reset();
  };

  const getTokenLocalStorage = (value) => {
    localStorage.setItem("recaptcha-token", value);
    value ? setClickCaptcha(true) : setClickCaptcha(false);
  };

  //LIMPA ERROS

  const clearErrors = () => {
    setError("");
    setPasswordError("");
  };

  //RESETA ESTADOS

  const resetState = () => {
    setEmail("");
    setSMSPage();
    setClickCaptcha(false);
    setCaptchaResponse();
    setMessage409();
    setError("");
    setPasswordError("");
    setError("");
    setClickCaptcha(false);
    setStatusEmail();
    setStatusRegistration();
    setNumber();
    setRegistrationPage();
    setError();
    setPlacaPage();
    setHome();
    setState409(false);
  };

  const value = {
    user,
    setUser,
    password,
    setPassword,
    email,
    setEmail,
    error,
    setError,
    passwordError,
    setPasswordError,
    confirmPassword,
    setConfirmPassword,
    clearErrors,
    verifyEmail,
    SMSPage,
    setSMSPage,
    redoCaptcha,
    reCaptchaRef,
    captchaResponse,
    clickCaptcha,
    setClickCaptcha,
    message409,
    resetState,
    statusEmail,
    verifyPhone,
    getTokenLocalStorage,
    loginEmail,
    placaRegistrationRedirectApp,
    errorRedirect,
    registrationPage,
    number,
    setNumber,
    statusRegistration,
    placaPage,
    placaRegistration,
    home,
    setHome,
    loading,
    setLoading,
    notification200,
    setNotification200,
    notification400,
    setNotification400,
    notification404,
    setNotification404,
    notification409,
    setNotification409,
    placaRegistrationMain,
    phoneReturnEmail,
    state409,
    fetchSignInMethodsForEmailFb,
    signInWithEmailAndPasswordFb,
    sendPasswordResetEmailFb,
    sendEmailVerificationFb,
    createUserWithEmailAndPasswordFb,
    refreshFirebaseToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
