
export const urlAPI = 'https://api-labs.localiza.com/GF.MeuCarro.API/v1/api';
export const DominioApi = 'https://api-labs.localiza.com/GF.MeuCarro.API/v1';
export const NomeCookie = 'SU_MeuCarro';
export const NomeCookiePlaca = 'SU_PLC_MeuCarro';
export const CaptchaSiteKey = "6Le7Lt0bAAAAAMXdc9dV5ZSaqDv5F0wuc9hyBgBj";
export const TamanhoSenhaTemporaria = 6;
export const NomeCookieUrlForcada = 'U_forced';
export const TempoReenvioSmsMilisegundos = 25000;
export const NomeCookieSessaoRea = 'U_forced_rea';
export const NomeCookieTipoLogin = 'T_Login';
export const NomeCookieBanner = 'to_read_cookie';
export const UrlBanner = '';
export const MinutosEsperaCookieSms = 4.5;
export const TempoCookieSessaoRea = 50;
export const urlAdrum = 'https://js.localiza.com/js/appdynamics/meucarro.localiza.com/adrum.js';
export const NomeAtributoHeaderHttpAutorizacao = 'Authorization';

export const datadogApplicationId = "ce47e860-e236-4358-ba96-db91326a333e";
export const datadogClientToken = "pub3aadfc83d12920ffb1482b06e1d2b871";
export const datadogSite = "datadoghq.com";
export const datadogService = "#meucarro-prd";