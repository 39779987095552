import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { CaptchaSiteKey } from "../../../config/consts";
import ReCAPTCHA from "react-google-recaptcha";
import {
  FormContainer,
  LabelWrapper,
  InputContainer,
  TextFieldMaterial,
  ButtonContainer,
  Button,
  ErrorContainer,
  MessageError,
  LinkContainer,
  Ancora,
  Aviso,
  AvisoContainer,
  WrapperAviso,
  WrapperAvisoTitle,
  OkLink,
  ContainerOfButtons,
  OkLinkAncor,
  OkLinkButton,
} from "./styles";
import fire from "../../fire";
import { useAuth } from "../../../providers/auth";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import Loading from "../../Loading";
import Tracking from "../../../hooks/Tracking";
import Warning from "../../../images/Warning.png";
import checkAviso from "../../../images/checkAviso.svg";
import { isIOS, isMobile } from "react-device-detect";
import "../../../functions/newCookies";

export const LoginEmailContainer = () => {
  const {
    email,
    setEmail,
    error,
    setError,
    clearErrors,
    verifyEmail,
    SMSPage,
    getTokenLocalStorage,
    redoCaptcha,
    reCaptchaRef,
    captchaResponse,
    clickCaptcha,
    message409,
    password,
    setPassword,
    passwordError,
    setPasswordError,
    statusEmail,
    loginEmail,
    placaPage,
    home,
    setHome,
    loading,
    resetState,
    state409,
    notification400,
    setNotification400,
    fetchSignInMethodsForEmailFb,
    signInWithEmailAndPasswordFb,
    refreshFirebaseToken,
  } = useAuth();
  const [senhaCadastroPage, setSenhaCadastroPage] = useState(false);
  const [result, setResult] = useState(false);
  const [statusPassword, setStatusPassword] = useState(false);
  const history = useHistory();

  const showPassword = () => setStatusPassword(true);

  const hidePassword = () => setStatusPassword(false);

  const handleType = () => (statusPassword === false ? "password" : "text");

  const special =
    /^(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#;$%*(){}_+^&]/;

  const redirectToResendEmail = () => {
    history.push("/LoginReenviarEmail");
  };

  const redirectToRenavam = () => {
    history.push("/LoginRenavamMain");
  };

  const redirectToPhone = () => {
    history.push("/LoginCelular");
  };

  const handleLoginEmail = () => {
    if (email === "") {
      setError("O campo e-mail é obrigatório");
    } else if (email.length <= 6 || !special.exec(email)) {
      setError("Digite um e-mail válido");
    } else if (clickCaptcha === false) {
      setError("Por favor, clique em não sou um robô");
    } else {
      clearErrors();
      fetchSignInMethodsForEmailFb(email)
        .then((result) => {
          verifyEmail(email);
          if (result.status === 200) {
            setResult(true);
            return;
          }
          if (result.response.status === 400) {
            setSenhaCadastroPage(true);
          }
        })
        .catch((err) => {
          setError("Serviço indisponível. Tente novamente mais tarde.");
        });
    }
  };

  const handleLoginPassword = () => {
    if (password === "") {
      setError("O campo senha é obrigatório");
    } else {
      clearErrors();
      signInWithEmailAndPasswordFb("Email", email, password)
        .then((result) => {
          if (result.data.Authenticated.IsEmailVerified === true) {
            localStorage.setItem(
              "token-id",
              result.data.Authenticated.FirebaseToken
            );
            localStorage.setItem(
              "refresh-token",
              result.data.Authenticated.RefreshToken
            );
            loginEmail(
              email,
              localStorage.getItem("token-id"),
              localStorage.getItem("recaptcha-token")
            );
            return;
          }
          if (result.data.Authenticated.IsEmailVerified === false) {
            localStorage.setItem(
              "token-id",
              result.data.Authenticated.FirebaseToken
            );
            redirectToResendEmail();
          }
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    localStorage.removeItem("token-id");
    localStorage.removeItem("user-name");
    localStorage.removeItem("user-data");
    localStorage.removeItem("placa-atual");
    localStorage.removeItem("modelo-atual");
    localStorage.removeItem("codigo-identificador-atual");
    localStorage.removeItem("cnpj-atual");
    localStorage.removeItem("sigla-contrato");
    localStorage.removeItem("nome-empresa");
    localStorage.removeItem("razao-social");
    localStorage.removeItem("sigla-contrato");
    localStorage.removeItem("recaptcha-token");
    localStorage.removeItem("renavam");
    localStorage.removeItem("user-email");
    localStorage.removeItem("refresh-token");
    resetState();
  }, []);

  const sendAnalytics = (action) => {
    Tracking.sendEvent({
      category: "Login_email",
      action: action,
      label: "Redirecionamento frota 360 por e-mail.",
    });
  };

  return (
    <React.Fragment>
      <FormContainer>
        <LabelWrapper>Faça seu login</LabelWrapper>
        <InputContainer>
          <TextFieldMaterial
            id="Email"
            label="Informe seu e-mail"
            variant="outlined"
            name="email"
            type="text"
            onChange={(e) => setEmail(e.target.value.trim())}
            value={email}
            InputLabelProps={{
              style: { color: "#3A3D42", fontSize: "14px" },
            }}
          />
          {statusEmail === true && result === true ? (
            <TextFieldMaterial
              id="Senha"
              label="Informe sua senha"
              variant="outlined"
              name="senha"
              type={handleType()}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              InputLabelProps={{
                style: { color: "#3A3D42", fontSize: "14px" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {statusPassword === false ? (
                      <IconButton edge="end" onClick={showPassword}>
                        <VisibilityOff />
                      </IconButton>
                    ) : (
                      <IconButton edge="end" onClick={hidePassword}>
                        <Visibility />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            ""
          )}
        </InputContainer>
        {error ? (
          <ErrorContainer>
            <MessageError>{error}</MessageError>
          </ErrorContainer>
        ) : (
          ""
        )}
        {passwordError ? (
          <ErrorContainer>
            <MessageError>{passwordError}</MessageError>
          </ErrorContainer>
        ) : (
          ""
        )}
        {clickCaptcha === true && notification400 === true ? (
          <React.Fragment>
            {sendAnalytics("REDIRECT_EMAIL_FROTA360_POPUP")}
            <AvisoContainer>
              <Aviso>
                <img
                  src={checkAviso}
                  alt=""
                  style={{ width: 34, height: 34 }}
                />
                <WrapperAvisoTitle>Acesse o Frota360</WrapperAvisoTitle>
                {isMobile ? (
                  <WrapperAviso>
                    Opa! Para esse e-mail, por favor acesse o aplicativo
                    Frota360.
                  </WrapperAviso>
                ) : (
                  <WrapperAviso>
                    Opa! Para esse e-mail, por favor acesse o site Frota360.
                  </WrapperAviso>
                )}
                <ContainerOfButtons>
                  <OkLinkButton
                    onClick={() => {
                      setNotification400(false);
                      sendAnalytics("REDIRECT_EMAIL_FROTA360_FECHAR");
                    }}
                  >
                    Fechar
                  </OkLinkButton>
                  {isMobile ? (
                    isIOS ? (
                      <OkLinkAncor
                        href="https://apps.apple.com/br/app/localiza-frota-360/id1521903141"
                        onClick={() => {
                          setNotification400(false);
                          sendAnalytics("REDIRECT_EMAIL_FROTA360_IOS");
                        }}
                      >
                        Baixar o app
                      </OkLinkAncor>
                    ) : (
                      <OkLinkAncor
                        href="https://play.google.com/store/apps/details?id=com.unidas.frotas360app"
                        onClick={() => {
                          setNotification400(false);
                          sendAnalytics("REDIRECT_EMAIL_FROTA360_ANDROID");
                        }}
                      >
                        Baixar o app
                      </OkLinkAncor>
                    )
                  ) : (
                    <OkLinkAncor
                      href="https://autenticacao.localiza.com/login?source_system=https:%2F%2Ffrota360.localiza.com%2Fhome&system_code=PORTAL_CLIENTE"
                      onClick={() => {
                        setNotification400(false);
                        sendAnalytics("REDIRECT_EMAIL_FROTA360_SITE");
                      }}
                    >
                      Acessar o site
                    </OkLinkAncor>
                  )}
                </ContainerOfButtons>
              </Aviso>
            </AvisoContainer>
          </React.Fragment>
        ) : (
          ""
        )}
        <ReCAPTCHA
          onChange={getTokenLocalStorage}
          ref={reCaptchaRef}
          sitekey={CaptchaSiteKey}
          onExpired={() => redoCaptcha}
        />

        <ButtonContainer>
          {statusEmail === true && result === true ? (
            <Button onClick={handleLoginPassword}>Entrar</Button>
          ) : (
            <Button onClick={handleLoginEmail}>Prosseguir</Button>
          )}
          <Button isOutlined onClick={redirectToRenavam}>
            Entrar com Placa e Renavam
          </Button>
        </ButtonContainer>
        {statusEmail === true && result === true ? (
          <LinkContainer>
            <Link to="/LoginNovaSenha">
              <Ancora>Esqueci minha senha</Ancora>
            </Link>
          </LinkContainer>
        ) : (
          <LinkContainer>
            <Link to="/LoginCelular">
              <Ancora>Não sei qual e-mail usar</Ancora>
            </Link>
          </LinkContainer>
        )}
        {loading === true ? <Loading /> : ""}
        {clickCaptcha === true && SMSPage === true && state409 === false ? (
          <React.Fragment>
            <AvisoContainer>
              <Aviso>
                <img src={Warning} alt="" style={{ width: 34, height: 34 }} />
                <WrapperAvisoTitle>
                  Não encontramos seu e-mail no sistema
                </WrapperAvisoTitle>
                <WrapperAviso>
                  Na tela a seguir, digite seu número de celular para
                  consultarmos se você possui algum e-mail cadastrado.
                </WrapperAviso>
                <OkLink onClick={redirectToPhone}>OK</OkLink>
              </Aviso>
            </AvisoContainer>
          </React.Fragment>
        ) : (
          ""
        )}
        {clickCaptcha === true && SMSPage === true && state409 === true ? (
          <React.Fragment>
            <AvisoContainer>
              <Aviso>
                <img src={Warning} alt="" style={{ width: 34, height: 34 }} />
                <WrapperAvisoTitle>
                  Seu e-mail está associado a mais de um cadastro no sistema
                </WrapperAvisoTitle>
                <WrapperAviso>
                  Por favor, faça login por placa e renavam.
                </WrapperAviso>
                <OkLink onClick={redirectToRenavam}>OK</OkLink>
              </Aviso>
            </AvisoContainer>
          </React.Fragment>
        ) : (
          ""
        )}
      </FormContainer>
      {clickCaptcha === true &&
      SMSPage === false &&
      senhaCadastroPage === true ? (
        <Redirect to="/LoginSenhaCadastro" />
      ) : (
        ""
      )}
      {clickCaptcha === true && placaPage === true ? (
        <Redirect to="/LoginRenavamEmail" />
      ) : (
        ""
      )}
      {clickCaptcha === true && home === true ? (
        <Redirect
          to={{
            pathname: "/Home",
            state: { id: "Login" },
          }}
        />
      ) : (
        ""
      )}
      {isMobile ? (
        <span
          className="cmp-revoke-consent"
          style={{ cursor: "pointer" }}
          onClick={() => showConsentPreferencesPopup()}
        >
          Configurações de cookies
        </span>
      ) : null}
    </React.Fragment>
  );
};
